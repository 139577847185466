import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider,Dialog,
  DialogActions,
  DialogContent,
  DialogTitle } from '@mui/material';
import DateRange from '@mui/icons-material/DateRange';  
import vendorIcon from '../Images/vendor icon.png';
import adminIcon from '../Images/admin icon.png';
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';
// import { MdLocationOn, MdBusiness } from 'react-icons/md';
import axios from 'axios';
import api from '../Services/api';
import {Col} from "react-bootstrap";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MyLocationIcon from '@mui/icons-material/MyLocation';


const Profile = () => {

  const [originalData, setOriginalData] = useState({});
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [contactData, setContactData] = useState({
          vendorId : " ",
          assessorId :" ",
          companyId : " ",
          // companyName: " ",
          // companyAddress : " ",
          // stateId: "",
          // placeName: "",
          // contactPersonName : " ",
          vendorCompanyName : " ",
          vendorCompanyAddress: "",
          natureOfWork: "",
          vendorName: " ",
          vendorPhoneNumber:" ",
          vendorEmail: " ",
          vendorPhoneNumber1:" ",
          vendorEmail1: " ",
          vendorPincode:"",
          vendorPlaceName: "",
          vendorStateId: "",
          vendorContractStartDate: " ",
          vendorContractEndDate: " ",

  });

 

  const [assessorData, setAssessorData] = useState({      
          assessorId: "",
          clientId: "",
          assessorCompanyName: "",
          assessorAddress: "",
          assessorPlaceName: "",
          assessorStateId: "",
          assessorPinCode: "",
          assessorName: "",
          assessorEmail: "",
          assessorPhoneNumber: "",
          assessorEmail1: "",
          assessorPhoneNumber1: ""
       
  });
  const [companyData, setCompanyData] = useState({
    principleEmployer: '',
    companyAddress: '',
   
    // companyEmailId: '',
    // companyPhoneNumber: '',
    clientName:'',
      clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId1: '',
    natureOfBusiness: '',
    stateId: '',
    placeName: '',
    pinCode: '',
   
  });


  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^(?!0000000000$)[0-9]{10}$/;
   
    
    
    if (!contactData.vendorName) newErrors.vendorName = 'Vendor Name is required';
   
    if (!emailPattern.test(contactData.vendorEmail)) {
      newErrors.vendorEmail = contactData.vendorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
  
    if (!phonePattern.test(contactData.vendorPhoneNumber)) {
      newErrors.vendorPhoneNumber = contactData.vendorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  useEffect(() =>{
    api.vendor.findvendor()
    .then(response =>{
      const temp = response.data.filtterData;
      console.log("temp",temp);
      setContactData(temp);
      setOriginalData(temp);
    })
    .catch(error => console.error('error fetching profile data:', error))
  }, []);

  

  useEffect(() => {
    const id = contactData.assessorId;
     console.log("assessorid:",id);
    api.assessor.findOne(id)
    .then(response => {
      const temp1 = response.data;
    console.log("temp1",temp1);
       setAssessorData(temp1)
    
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);


  useEffect(() => {
    const requestData1 = contactData.companyId;
     console.log("id1:",requestData1);
    api.client.getClient(requestData1)
    .then(response => {
      // console.log("masterdata:", response);
      const temp2 = response.data;
    console.log("temp2",temp2);
       setCompanyData(temp2)
      //  setOriginalData(temp2)
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);

  const handleEdit = () => {
    setOriginalData({...contactData});
    setOpenDialog(true); 
  };

  const handleCancelEdit = () => {
    setOpenDialog(false); 
    setContactData({...originalData});
   
  };

  
 
  const handleSave = async ()=>{
    let id = contactData.vendorId;
    if(validateForm()){
      try{
    const response = await api.vendor.updateOne(id, contactData)
   
      if(response.status === 200){
      window.alert("Edited Successfully");
      setOriginalData({ ...contactData });
      setOpenDialog(false); 
      }
      else{
        window.alert("error in editing the details");
      }
    
      } catch(error){
        console.error('Error updating',error)
      }
  }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>

 
        <Card className="d-md-flex  m-5" >
     
      
          <Col className=" p-4">
          <div className='d-flex'>
          <img  className="bg-light" src={clientIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2" variant="h6" gutterBottom>
        Client
    </Typography>
          </div>
         
               <div >
           
         
            <Typography className="mt-2 " variant="body2"  component="div">
            <FaBuilding /> Principal Employer : {companyData?.data?.principleEmployer}
            </Typography>
            <Typography className="mt-2 " variant="body2" component="div">
    <FaUserAlt /> Client Name : {companyData?.data?.clientName}
    </Typography>
            <Typography  className="mt-2 " variant="body2"  component="div">
            <FaMapMarkerAlt /> Address : {companyData?.data?.companyAddress}, {companyData?.data?.placeName} {companyData?.data?.stateId}- {companyData?.data?.pinCode}
            </Typography>
            
            </div>
          
            <div className="pt-3">
    <Typography  variant="h6" gutterBottom>
        Contact Person Details
    </Typography>
   
   
    <Typography className="mt-2" variant="body2" component="div">
    <FaUserAlt /> Name : {companyData?.data?.clientContactPersonName}
    </Typography>
    <Typography className="mt-2 " variant="body2"  component="div" >
    <FaEnvelope />  Email : {companyData?.data?.clientContactPersonEmailId} {companyData?.data?.clientContactPersonEmailId1 && `, ${companyData?.data?.clientContactPersonEmailId1}`}
    </Typography>
    <Typography className="mt-2 " variant="body2"  component="div" >
    <FaPhone />  Phone Number : {companyData?.data?.clientContactPersonPhoneNumber} {companyData?.data?.clientContactPersonPhoneNumber1 && `, ${companyData?.data?.clientContactPersonPhoneNumber1}`}
    </Typography>
    </div>
          </Col>
         
           <div className='vr'></div>
           <Col className=" p-4">
        <div className='d-flex' >
          <img   className='bg-light' src={assesorIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2" variant="h6" gutterBottom>
        Assessor 
  </Typography>
  </div>
        <div>
    
  
     <Typography className="mt-2" variant="body2"  component="div">
     <FaBuilding /> Company Name : {assessorData?.data?.assessorCompanyName}
     </Typography>
     <Typography className="mt-2" variant="body2" component="div">
<FaUserAlt />  Address : {assessorData?.data?.assessorAddress}
</Typography>

     </div>
   
     <div className='pt-3'>
<Typography  variant="h6" gutterBottom>
 Contact Person Details
</Typography>


<Typography className="mt-2" variant="body2" component="div">
<FaUserAlt />  Name : {assessorData?.data?.assessorName}
</Typography>
<Typography className="mt-2 " variant="body2"  component="div" >
<FaEnvelope />  Email : {assessorData?.data?.assessorEmail} {assessorData?.data?.assessorEmail1 && `, ${assessorData?.data?.assessorEmail1}`}
</Typography>
<Typography className="mt-2 " variant="body2"  component="div" >
<FaPhone />  Phone Number : {assessorData?.data?.assessorPhoneNumber} {assessorData?.data?.assessorPhoneNumber1 && `, ${assessorData?.data?.assessorPhoneNumber1}`}
</Typography>
</div>

   </Col>
        <div className="vr"></div>
        <Col className="bg-dark">
        <button className='pt-2 btn btn-sm btn-secondary float-end p-3' onClick={handleEdit} style={{border:'0.5px solid black',borderRadius:'0px 0px 0px 50px'}}>Edit</button>
          <CardContent className="card-content  p-4 " >
          <div className='d-flex' >
          <img   className='bg-light' src={vendorIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2 text-white" variant="h6" gutterBottom>
        Vendor
  </Typography>
  </div>
         
            <Typography className="mt-2 text-white" variant="body2"  component="div">
            <FaBuilding /> Company Name : {contactData.vendorCompanyName}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
          <FaMapMarkerAlt /> Address : {contactData.vendorCompanyAddress}, {contactData.vendorPlaceName} {contactData.vendorStateId}- {contactData.vendorPincode}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaMapMarkerAlt /> Nature Of Work : {contactData.natureOfWork}
            </Typography>
           
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaEnvelope /> Email : {contactData.vendorEmail} {contactData.vendorEmail1 && `, ${contactData.vendorEmail1}`}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaPhone /> Phone Number : {contactData.vendorPhoneNumber} {contactData.vendorPhoneNumber1 && `, ${contactData.vendorPhoneNumber1}`}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange /> Contract Start Date : {contactData.vendorContractEndDate.slice(0 ,10)}
            </Typography>
             <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange/> Contract End Date : {contactData.vendorContractEndDate.slice(0, 10)}
            </Typography> 


          </CardContent>
          </Col>
         
 


        </Card>
      

        <Dialog open={openDialog} onClose={handleCancelEdit} maxWidth="md" fullWidth>
        <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT VENDOR PROFILE</h6>
        <DialogContent >
          <Grid container spacing={2}>
          
       
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Vendor Name"
                name="vendorName"
                value={contactData.vendorName}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorName}
              helperText={errors.vendorName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="vendorEmail"
                value={contactData.vendorEmail}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorEmail}
              helperText={errors.vendorEmail}
              />
            </Grid>
           
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alternative Email"
                name="vendorEmail1"
                value={contactData.vendorEmail1}
                onChange={handleInputChange}
                margin="normal"
              
              />
            </Grid>
            
          
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="vendorPhoneNumber"
                value={contactData.vendorPhoneNumber}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorPhoneNumber}
              helperText={errors.vendorPhoneNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alternative PhoneNumber"
                name="vendorPhoneNumber1"
                value={contactData.vendorPhoneNumber1}
                onChange={handleInputChange}
                margin="normal"
               
              />
              
            </Grid>
         
          </Grid>
        </DialogContent>
       
          <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancelEdit} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} disabled={!validateForm}>
            Save
          </button>
          </div>
      
      </Dialog>


    </>
  );
};

export default Profile;