import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid,Typography, TextField, Select, MenuItem, Checkbox, Radio, FormControlLabel, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircle, Email, Phone, Add ,LocationOn, VerifiedUserOutlined, Lock, DateRange, FormatListNumbered, Person } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { COLORS } from '../Component/colors';
import api from '../Services/api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Link ,useNavigate} from 'react-router-dom';

const useStyles = makeStyles({
 

  detail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 17,
    
  },
  icon: {
    marginRight: 10,
    
    
  },
  textField: {
    width: '60%',
    '& .MuiInputLabel-root': {
      color: 'black', // Default label color (or set to something else)
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black', // Outline color to white when not focused
      },
      '&:hover fieldset': {
        borderColor: 'black', // Optional: Outline color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black', // Optional: Outline color when focused
      },
    },
    // When the field is focused, change label color to white
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black',
    },
    // Text inside input area when focused
    '& .MuiOutlinedInput-input': {
      color: 'black', // Text color inside the input area when focused
    },
    // Ensure the text inside is visible when the field is not focused
    '& .MuiOutlinedInput-input.Mui-focused': {
      color: 'black', // Make text black when focused to keep it visible
    },
  },
  
});

const statesInIndia = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Ladakh', 'Lakshadweep', 'Puducherry'];


const CompanyCard = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    principleEmployer: '',
    stateId: '',
    placeName: '',
    pinCode:'',
    companyAddress: '',
    authorisedPersonName: '',
    companyEmailId: '',
    companyEmailId1: '',
    companyPhoneNumber: '',
    companyPhoneNumber1: '',
    clientName: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId: '',
    clientContactPersonEmailId1: '',
    isClient: true,
    clientUsername: '',
    clientPassword: '',
    startDate: '',
    endDate: '',

  });
  const navigate=useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [extraEmailCompanyVisible, setExtraEmailCompanyVisible] = useState(false);
  const [extraPhoneNumberCompanyVisible, setExtraPhoneNumberCompanyVisible] = useState(false);
  const [extraEmailClientVisible, setExtraEmailClientVisible] = useState(false);
  const [extraPhoneNumberClientVisible, setExtraPhoneNumberClientVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAddEmailCompanyField = () => {
    setExtraEmailCompanyVisible(true);
  };

  const handleAddPhoneNumberCompanyField = () => {
    setExtraPhoneNumberCompanyVisible(true);
  };
  const handleAddEmailClientField = () => {
    setExtraEmailClientVisible(true);
  };

  const handleAddPhoneNumberClientField = () => {
    setExtraPhoneNumberClientVisible(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  //VALIDATION
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^(?!0000000000$)[0-9]{10}$/;
    const pinCodePattern = /^(?!000000$)[0-9]{6}$/;

    
    if (!formData.principleEmployer) newErrors.principleEmployer = 'Company Name is required';
    if (!formData.stateId) newErrors.stateId = 'State is required';
    if (!formData.placeName) newErrors.placeName = 'Place is required';
    if (!formData.companyAddress) newErrors.companyAddress = 'Address is required';
    if (!formData.authorisedPersonName) newErrors.authorisedPersonName = 'Conatct Person name is required';

    if (!emailPattern.test(formData.companyEmailId)) {
      newErrors.companyEmailId = formData.companyEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    // if (!emailPattern.test(formData.companyEmailId1)) {
    //   newErrors.companyEmailId1 = formData.companyEmailId1 ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    // }
    if(!pinCodePattern.test(formData.pinCode)){
      newErrors.pinCode = formData.pinCode ? 'Invalid Pin code format(245678)' : 'Pin code required';
    }
    if (!phonePattern.test(formData.companyPhoneNumber)) {
      newErrors.companyPhoneNumber = formData.companyPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    // if (!phonePattern.test(formData.companyPhoneNumber1)) {
    //   newErrors.companyPhoneNumber1 = formData.companyPhoneNumber1 ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    // }
    if (!formData.clientName) newErrors.clientName = 'Contractor Name  is required';
    if (!formData.clientContactPersonName) newErrors.clientContactPersonName = 'Contact Person Name is required';
    if (!emailPattern.test(formData.clientContactPersonEmailId)) {
      newErrors.clientContactPersonEmailId = formData.clientContactPersonEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.clientContactPersonPhoneNumber)) {
      newErrors.clientContactPersonPhoneNumber = formData.clientContactPersonPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    // if (!emailPattern.test(formData.clientContactPersonEmailId1)) {
    //   newErrors.clientContactPersonEmailId1 = formData.clientContactPersonEmailId1 ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    // }
    // if (!phonePattern.test(formData.clientContactPersonPhoneNumber1)) {
    //   newErrors.clientContactPersonPhoneNumber1 = formData.clientContactPersonPhoneNumber1 ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    // }
    if (!formData.startDate) newErrors.startDate = 'Contract Start Date is required';
    if (!formData.endDate) newErrors.endDate = 'Contract End Date is required';
    if (!formData.clientUsername) newErrors.clientUsername = 'Username is required';
    if (!formData.clientPassword) newErrors.clientPassword = 'Password is required';
    const selectField = formData.stateId;
    if (!selectField) {
      newErrors.selectField = 'Please select State';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  //CREATING CLIENT PROFILE
  const handleSave = async () => {
    if (validateForm()) {
      let data = sessionStorage.getItem('user');
      let data1 = JSON.parse(data);
      let type = data1.type;
     
      try {
        if (type === 'admin'){
       const response =  await api.client.createClient(formData)
          if (response.status === 400) {
            
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("The username you Entered is already in use. Please enter a different one")
          }
         else if (response.status === 201) {
            const clientData = response.data;
            sessionStorage.setItem('client', JSON.stringify(clientData));

            setSnackbarOpen(true);
            setSnackbarMessage("Company Created Succesfully");
            
            setFormData({
              principleEmployer: '',
              stateId: '',
              placeName: '',
              pinCode:'',
              companyAddress: '',
              authorisedPersonName: '',
              companyEmailId: '',
              companyEmailId1: '',
              companyPhoneNumber: '',
              companyPhoneNumber1: '',
              clientName: '',
              clientContactPersonName: '',
              clientContactPersonPhoneNumber: '',
              clientContactPersonPhoneNumber1: '',
              clientContactPersonEmailId: '',
              clientContactPersonEmailId1: '',
              isClient: true,
              clientUsername: '',
              clientPassword: '',
              startDate: '',
              endDate: '',
            });
            handleSubmit();
          }

        
      }
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Only admin can create company");
        navigate('/');
            }
    }
    
   
  
        catch(error) {
          console.error('Error saving company data:', error);
         
          
        }
      }
  }
  


  //CLIENT MASTERDATA CREATION
  const handleSubmit = async() => {
    let data = sessionStorage.getItem('client');
    let clientData = JSON.parse(data);
    const id = clientData.data.companyId;
    const masterData = {
      companyId: id,
      dateOfCommencement: {
        value: ""
      },
      dateOfCompletion: {
        value: ""
      },
      poCopy: {
        value: ""
      },
      epfoAllotment: {
        value: ""
      },
      esicAllotment: {
        value: ""
      },
      pTaxRc: {
        value: ""
      },
      ptEc: {
        value: ""
      },
      lwf: {
        value: ""
      },
      factoryLicence: {
        value: ""
      },
      buildingPlanLayout: {
        value: ""
      },
      stabilityCertificate: {
        value: ""
      },
      concernToEstablishment: {
        value: ""
      },
      concernToOperations: {
        value: ""
      },
      fireAdvisory: {
        value: ""
      },
      fireNoc: {
        value: ""
      },
      environmentClearance: {
        value: ""
      },
      medicalOrHospitalCertificate: {
        value: ""
      },
      apprenticeshipRegistration: {
        value: ""
      },
      neemOrNapsRegistration: {
        value: ""
      },
      clraRc: {
        value: ""
      },
      sAndCeRc: {
        value: ""
      },
      clraLicence: {
        value: ""
      },
      bocwRc: {
        value: ""
      },
      ismwRc: {
        value: ""
      },
      ismwLicence: {
        value: ""
      },
      passaraLicence: {
        value: ""
      },
      fssaiLicence: {
        value: ""
      },


    }

 try{
    const response = await api.masterData.createMasterData(masterData) 
      if(response.status === 201){
       
        setSnackbarOpen(true);
        setSnackbarMessage("Client MasterData created successfully")
        setFormData({
          principleEmployer: '',
    stateId: '',
    placeName: '',
    pinCode:'',
    companyAddress: '',
    authorisedPersonName: '',
    companyEmailId: '',
    companyEmailId1: '',
    companyPhoneNumber: '',
    companyPhoneNumber1: '',
    clientName: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId: '',
    clientContactPersonEmailId1: '',
    isClient: true,
    clientUsername: '',
    clientPassword: '',
    startDate: '',
    endDate: '',
        });
      }
      else if(response.status === 400){
        
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("MasterData Already exists")
      }
      else{
       
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Some error occured,try again");
        setFormData({
          principleEmployer: '',
    stateId: '',
    placeName: '',
    pinCode:'',
    companyAddress: '',
    authorisedPersonName: '',
    companyEmailId: '',
    companyEmailId1: '',
    companyPhoneNumber: '',
    companyPhoneNumber1: '',
    clientName: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId: '',
    clientContactPersonEmailId1: '',
    isClient: true,
    clientUsername: '',
    clientPassword: '',
    startDate: '',
    endDate: '',
        });
      }
    
    }
      catch(error) {
        console.error('Error saving company master data:', error);
      
        
      }
  };



  return (
    <div >
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
         <h5 className="pt-4 px-4 pb-1">Create Client <i class="bi bi-arrow-right-square-fill" style={{color:'#B22222'}}></i></h5>
   <Card   sx={{ marginLeft:'22px',marginRight:'22px'}}>

  <CardContent>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
     <h6 className="text-dark">Client Details <ArrowDropDownIcon sx={{ color: 'black',fontSize:'40px',letterSpacing:'1px' }} /></h6>
        <div className={classes.detail}>
          {/* <AccountCircle className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Name of the principal Employer"
            name="principleEmployer"
         
            value={formData.principleEmployer}
            onChange={handleChange}
            error={!!errors.principleEmployer}
            helperText={errors.principleEmployer}
           
          />
        </div>

        <div className={classes.detail}>
          {/* <LocationOn className={classes.icon} /> */}
          <Select
          // style={{border:'1px solid whitesmoke'}}
            className={classes.textField}
            label="State ID"
            value={formData.stateId}
            onChange={handleChange}
             
            name="stateId"
            displayEmpty
            error={!!errors.stateId}
          >
            <MenuItem value="" disabled>Select State</MenuItem>
            {statesInIndia.map((state, index) => (
              <MenuItem key={index} value={state}>{state}</MenuItem>
            ))}
          </Select>
        </div>
        {errors.selectField && (
            <div className="pb-3  text-center" style={{ color: 'red', fontSize: '14px' }}>
              {errors.selectField}
            </div>
          )}
        <div className={classes.detail}>
          {/* <MyLocationIcon className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Place"
            name="placeName"
            value={formData.placeName}
            onChange={handleChange}
           
            error={!!errors.placeName}
            helperText={errors.placeName}
          />
        </div>

        <div className={classes.detail}>
          {/* <LocationOn className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Address"
            name="companyAddress"
          
            value={formData.companyAddress}
            onChange={handleChange}
            error={!!errors.companyAddress}
            helperText={errors.companyAddress}
          />
        </div>

        <div className={classes.detail}>
          {/* <LocationOn className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Pincode"
            name="pinCode"
            
            value={formData.pinCode}
            onChange={handleChange}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
        </div>

        <div className={classes.detail}>
          {/* <AccountCircle className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Name of authorised person"
            name="authorisedPersonName"
             
            value={formData.authorisedPersonName}
            onChange={handleChange}
            error={!!errors.authorisedPersonName}
            helperText={errors.authorisedPersonName}
          />
        </div>

        <div className={classes.detail}>
          {/* <Email className={classes.icon} /> */}
          <TextField
          sx={{marginLeft:'38px'}}
            className={classes.textField}
            label="Email"
            name="companyEmailId"
            value={formData.companyEmailId}
            onChange={handleChange}
            error={!!errors.companyEmailId}
            helperText={errors.companyEmailId}
          />
         <IconButton onClick={handleAddEmailCompanyField} style={{ marginLeft: '5px' }} color="primary">
            <Add sx={{ color: 'black' }} />
          </IconButton> 
        </div>

        {extraEmailCompanyVisible && (
          <div className={classes.detail}>
            {/* <MailOutlineIcon className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Alternative Email"
              name="companyEmailId1"
              value={formData.companyEmailId1}
              onChange={handleChange}
              error={!!errors.companyEmailId1}
              helperText={errors.companyEmailId1}
            />
          </div>
        )}


<div className={classes.detail}>
          {/* <Phone className={classes.icon} /> */}
          <TextField
          sx={{marginLeft:'38px'}}
            className={classes.textField}
            label="Phone Number"
            name="companyPhoneNumber"
            value={formData.companyPhoneNumber}
            onChange={handleChange}
            error={!!errors.companyPhoneNumber}
            helperText={errors.companyPhoneNumber}
          />
           <IconButton onClick={handleAddPhoneNumberCompanyField} style={{ marginLeft: '5px' }} color="primary">
            <Add sx={{ color: 'black' }} />
          </IconButton> 
        </div>

        {extraPhoneNumberCompanyVisible && (
          <div className={classes.detail}>
            {/* <PhoneIphoneIcon className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Alternative PhoneNumber"
              name="companyPhoneNumber1"
              value={formData.companyPhoneNumber1}
              onChange={handleChange}
              error={!!errors.companyPhoneNumber1}
              helperText={errors.companyPhoneNumber1}
            />
          </div>
        )}
      </Grid>

      {/* Contractor Details Column */}
      <Grid item xs={12} sm={6} >
      <h6 className="text-dark" >Contractor Details <ArrowDropDownIcon sx={{ color: 'black' ,fontSize:'40px',letterSpacing:'1px'}} /></h6>
   
        <div className={classes.detail}>
          {/* <Person className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Name of the contractor"
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            error={!!errors.clientName}
            helperText={errors.clientName}
          />
        </div>

        <div className={classes.detail}>
          {/* <Person className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Name of the authorised person"
            name="clientContactPersonName"
            value={formData.clientContactPersonName}
            onChange={handleChange}
            error={!!errors.clientContactPersonName}
            helperText={errors.clientContactPersonName}
          />
        </div>

        <div className={classes.detail}>
          {/* <Email className={classes.icon} /> */}
          <TextField
          sx={{marginLeft:'38px'}}
            className={classes.textField}
            label="Email"
            name="clientContactPersonEmailId"
            value={formData.clientContactPersonEmailId}
            onChange={handleChange}
            error={!!errors.clientContactPersonEmailId}
            helperText={errors.clientContactPersonEmailId}
          />
           <IconButton onClick={handleAddEmailClientField} style={{ marginLeft: '5px' }} color="primary">
            <Add sx={{ color: 'black' }} />
          </IconButton> 
        </div>

        {extraEmailClientVisible && (
          <div className={classes.detail}>
            {/* <MailOutlineIcon className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Alternative Email"
              name="clientContactPersonEmailId1"
              value={formData.clientContactPersonEmailId1}
              onChange={handleChange}
              error={!!errors.clientContactPersonEmailId1}
              helperText={errors.clientContactPersonEmailId1}
            />
          </div>
        )}

        <div className={classes.detail}>
          {/* <Phone className={classes.icon} /> */}
          <TextField
          sx={{marginLeft:'38px'}}
            className={classes.textField}
            label="Phone Number"
            name="clientContactPersonPhoneNumber"
            value={formData.clientContactPersonPhoneNumber}
            onChange={handleChange}
            error={!!errors.clientContactPersonPhoneNumber}
            helperText={errors.clientContactPersonPhoneNumber}
          />
           <IconButton onClick={handleAddPhoneNumberClientField} style={{ marginLeft: '5px' }} color="primary">
            <Add sx={{ color: 'black' }} />
          </IconButton> 
        </div>

        {extraPhoneNumberClientVisible && (
          <div className={classes.detail}>
            {/* <PhoneIphoneIcon className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Alternative PhoneNumber"
              name="clientContactPersonPhoneNumber1"
              value={formData.clientContactPersonPhoneNumber1}
              onChange={handleChange}
              error={!!errors.clientContactPersonPhoneNumber1}
              helperText={errors.clientContactPersonPhoneNumber1}
            />
          </div>
        )}

        <div className={classes.detail}>
          {/* <DateRange className={classes.icon} /> */}
          <TextField
         
            className={classes.textField}
            label="Contract Start Date"
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
        </div>

        <div className={classes.detail}>
          {/* <DateRange className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Contract End Date"
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
        </div>

        <div className={classes.detail}>
          {/* <AccountCircle className={classes.icon} /> */}
          <TextField
            className={classes.textField}
            label="Username"
            name="clientUsername"
            value={formData.clientUsername}
            onChange={handleChange}
            error={!!errors.clientUsername}
            helperText={errors.clientUsername}
            autoComplete="off" 
          />
        </div>

        <div className={classes.detail}>
          {/* <Lock className={classes.icon} /> */}
          <FormControl sx={{ width: '100%' }} >
            <TextField
            sx={{marginLeft:'20%'}}
              className={classes.textField}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff sx={{color:'black'}}/> : <Visibility sx={{color:'black'}} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Password"
              name="clientPassword"
              value={formData.clientPassword}
              onChange={handleChange}
              error={!!errors.clientPassword}
              helperText={errors.clientPassword}
              autoComplete="off" 
            />
          </FormControl>
        </div>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <button className="btn  btn-dark float-end text-white mx-0 mt-3 mb-3" disabled={!validateForm} onClick={handleSave}>
        Save
      </button>
    </Grid>
  </CardContent>
</Card>



    </div>
  );
};

export default CompanyCard;

