import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AppBarSelector from './Pages/AppBarSelector';
import { VendorList } from './Pages/VendorList';
import VendorProfile from './Pages/VendorProfile';
import ChangePass from './Pages/ChangePass';
import MasterData from './Component/MasterData';
import Forgot from './Pages/Forgot';
import Login from './Pages/Login';
import MasterdataTableAssessor from '../src/Component/MasterdataTableAssessor';
import AdminClient from './Pages/AdminClient';
import AsessorVendor from './Pages/AssessorVendor';
import Company from './Pages/Company';
import AdminAssessor from './Pages/AdminAssessor';
import Clientdashboard from './Pages/Dashboard/Client';
import Vendordashboard from './Pages/Dashboard/Vendor';
import Admindashboard from './Pages/Dashboard/Admin';
import AssesorDashboard from './Pages/Dashboard/Assessor';
import { AssessorProfile } from './Pages/AssessorProfile';
import MasterdataTableAdmin from './Component/MasterdataTableAdmin';
import { AssessorList } from './Pages/AssessorList';
import { ClientList } from './Pages/ClientList';
import { ClientProfile } from './Pages/ClientProfile';
import MasterdataTableClient from './Component/MasterdataTableClient';
import MonthlyData from './Component/MonthlyData';
import MonthlyData1 from './Component/MonthlyDataTableClient';
import MonthlyDataTableAssessor from './Component/MonthlyDataTableAssessor';
import MonthlyDataTableClient from './Component/MonthlyDataTableClient';
import PrivateRoute from './Services/PrivateRoute';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    
      <AppBarSelector>
        
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/createCompany" element={<PrivateRoute element={<AdminClient />} isLoggedIn={isLoggedIn} />} />
          <Route path="/createVendor" element={<PrivateRoute element={<AsessorVendor />} isLoggedIn={isLoggedIn} />} />
          <Route path="/createAssessor" element={<PrivateRoute element={<AdminAssessor />} isLoggedIn={isLoggedIn} />} />
          <Route path="/profile/vendor" element={<PrivateRoute element={<VendorProfile />} isLoggedIn={isLoggedIn} />} />
          <Route path="/profile/assessor" element={<PrivateRoute element={<AssessorProfile />} isLoggedIn={isLoggedIn} />} />
          <Route path="/company" element={<PrivateRoute element={<Company />} isLoggedIn={isLoggedIn} />} />
          <Route path="/changePass" element={<PrivateRoute element={<ChangePass />} isLoggedIn={isLoggedIn} />} />
          <Route path="/clientdashboard" element={<PrivateRoute element={<Clientdashboard />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendordashboard" element={<PrivateRoute element={<Vendordashboard />} isLoggedIn={isLoggedIn} />} />
          <Route path="/admindashboard" element={<PrivateRoute element={<Admindashboard />} isLoggedIn={isLoggedIn} />} />
          <Route path="/assessordashboard" element={<PrivateRoute element={<AssesorDashboard />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendorMasterData" element={<PrivateRoute element={<MasterdataTableAssessor />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendorsMasterData" element={<PrivateRoute element={<MasterdataTableAdmin />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendors-masterData" element={<PrivateRoute element={<MasterdataTableClient />} isLoggedIn={isLoggedIn} />} />
          <Route path="/forgotPassword" element={<PrivateRoute element= {<Forgot />} isLoggedIn={isLoggedIn} />} />
          <Route path="/masterdata" element={<PrivateRoute element={<MasterData />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendorList" element={<PrivateRoute element={<VendorList />} isLoggedIn={isLoggedIn} />} />
          <Route path="/clientList" element={<PrivateRoute element={<ClientList />} isLoggedIn={isLoggedIn} />} />
          <Route path="/client/profile" element={<PrivateRoute element={<ClientProfile />} isLoggedIn={isLoggedIn} />} />
          <Route path="/assessorList" element={<PrivateRoute element={<AssessorList />} isLoggedIn={isLoggedIn} />} />
          <Route path="/MonthlyData" element={<PrivateRoute element={<MonthlyData />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendorMonthlyData" element={<PrivateRoute element={<MonthlyDataTableAssessor />} isLoggedIn={isLoggedIn} />} />
          <Route path="/vendors-monthlyData" element={<PrivateRoute element={<MonthlyDataTableClient />} isLoggedIn={isLoggedIn} />} />
       </Routes>
      </AppBarSelector>
  
  );
};

export default App;
