import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider } from '@mui/material';
import DateRange from '@mui/icons-material/DateRange';  
import vendorIcon from '../Images/vendor icon.png';
import adminIcon from '../Images/admin icon.png';
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';
// import { MdLocationOn, MdBusiness } from 'react-icons/md';
import axios from 'axios';
import api from '../Services/api';
import {Col} from "react-bootstrap";
import { COLORS } from '../Component/colors';
import AccountCircle from '@mui/icons-material/AccountCircle';

export const ClientProfile = () => {
    let data1 = sessionStorage.getItem('user');
let clientData = JSON.parse(data1);
let id = clientData.id;
console.log('assessor Id',id);
    const [editMode, setEditMode] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [companyData, setCompanyData] = useState({ 

    principleEmployer: '',
    stateId: '',
    placeName: '',
    pinCode: '',
    companyAddress: '',
    authorisedPersonName: '',
    companyEmailId: '',
    companyPhoneNumber: '',
    companyEmailId1: '',
    companyPhoneNumber1: '',
    clientName: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId1: '',
    clientUsername: '',
    clientPassword: '',
    startDate:'',
    endDate: '',
       

  });


  useEffect(() =>{
    api.client.getClient(id)
    .then(response =>{
      const temp = response.data.data;
      console.log("temp",temp);
      setCompanyData(temp);
      
      setOriginalData(temp);
    })
    .catch(error => console.error('error fetching profile data:', error))
  }, []);

  



 

  const handleEdit = () => {
    setOriginalData({...companyData});
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setCompanyData({...originalData});
   
  };

  
 
  const handleSave = ()=>{
    setEditMode(false);
    
    api.client.editClient( id , companyData)
    .then((response) => {
      if(response.status===200){

     
      alert("Edited Successfully");
      setOriginalData({ ...companyData });
    }
    else{
      alert("error!")
    }
    })
    .catch(error =>{
      alert("Error occurred while updating")
    });
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Card className="d-md-flex m-5" style={{border:'2px solid rgba(0,0,0,0.1)'}}>
     
      
          <Col className=" p-4 bg-dark">
          <Typography className=" text-white" variant="h5" gutterBottom>
         <AccountCircle style={{color:'white',fontSize:'40px',border:'1px solid white',borderRadius:'100%'}}/> {companyData?.clientName}
    </Typography>
         
               <div >
          
         
            <Typography className="mt-2 text-white" variant="body2"  component="div">
            <FaBuilding /> Principal Employer : {companyData?.principleEmployer}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaMapMarkerAlt /> Address : {companyData?.companyAddress}, {companyData?.placeName} {companyData?.stateId}- {companyData?.pinCode}
            </Typography>
             {/* <Typography className="mt-2 text-white" variant="body2"  component="div" >
              <FaMapMarkerAlt /> Location : {companyData?.placeName}
            </Typography>  */}
        
            <Typography className="mt-2 text-white" variant="body2" component="div">
    <FaUserAlt /> Authorised Person Name : {companyData?.authorisedPersonName}
    </Typography>
    <Typography className="mt-2 text-white" variant="body2" component="div">
    <FaEnvelope /> Email : {companyData?.companyEmailId} {companyData?.companyEmailId1 && `, ${companyData?.companyEmailId1}`}
    </Typography>
    <Typography className="mt-2 text-white" variant="body2" component="div">
    <FaPhone />  Phone Number : {companyData?.companyPhoneNumber} {companyData?.companyPhoneNumber1 && `, ${companyData?.companyPhoneNumber1}`}
    </Typography>
         
            {/* <Typography className="mt-2 text-white" variant="body2" component="div">
    <FaUserAlt /> Contractor Name: {companyData?.clientName}
    </Typography> */}
    <Typography className="mt-2 text-white" variant="body2" component="div">
    <DateRange/>  Contract Start Date : {companyData?.startDate.slice(0, 10)}
    </Typography>
    <Typography className="mt-2 text-white" variant="body2" component="div">
    <DateRange/> Contract End Date : {companyData?.endDate.slice(0, 10)}
    </Typography>
           
            </div>
         
        
    <div className='mt-4 p-5 bg-secondary' >
          <div  className='text-center'>
            <Typography variant="body3" className="mt-3 text-white " component="div" >
            <FaUserAlt /> {' '}
              {editMode ? (
                <TextField
                  type="text"
                  name="clientContactPersonName"
                  value={companyData?.clientContactPersonName}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { color: '#000000' } }}
                  style={{backgroundColor:"white"}}
                  
                 
                />
              ) : (
                companyData?.clientContactPersonName
              )}
            </Typography>
            <Typography variant="body3"  className="mt-3 text-white" component="div" >
              <FaPhone />{' '}
              {editMode ? (
                <TextField
                  type="text"
                  name="clientContactPersonPhoneNumber"
                  value={companyData?.clientContactPersonPhoneNumber}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { color: '#000000' } }}
                  style={{backgroundColor:"white"}}
                
                  
                />
              ) : (
                companyData?.clientContactPersonPhoneNumber
              )}
            </Typography>
            <Typography variant="body3"  className="mt-3 text-white" component="div" >
              <FaEnvelope />{' '}
              {editMode ? (
                <TextField
                  type="text"
                  name="clientContactPersonEmailId"
                  value={companyData?.clientContactPersonEmailId}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { color: '#000000' } }}
                  style={{backgroundColor:"white"}}                   
                 
                  
                />
              ) : (
                companyData?.clientContactPersonEmailId
              )}
            </Typography>
            
          </div>
          {/* Edit and Save Buttons */}
          {editMode ? (
            <div className="mt-3 pb-3 text-center" >
                <button onClick={handleCancelEdit} className="mx-3 btn btn-sm btn-danger" >
                Cancel
              </button>
              <button onClick={handleSave} className='mx-2 btn btn-sm btn-success'  >
                Save
              </button>
            
            </div>
          ) : (
            <div className='text-center'>
            <button onClick={handleEdit} className=' btn btn-sm btn-dark text-white' style={{position:'relative',top:'-180px',border:'1px solid'}} >
              Edit
            </button>
            </div>
          )}
    </div>
   
         
          </Col>
       
         
 


        </Card>
  )
}
